<template>
  <div>
    <header-nav :bg="bg" :text="text" :index="4"/>
    <div class="h1">关于研小白英语</div>
    <div class="desc">
      研小白英语是考研人可以选择信任的考研教育平台，我们有出色的教研团队，所设涉及的科目有政治、英语、管综、数学。
      有出色的运营团队，所涉及的微信公众号有小白考研，小白考管综，小白教育学，小白考研资讯，小白读研等等。
      我们有出色的技术团队，用心研发出了研小白英语app，小白考研pro小程序，小白考研政治小程序等。
      相信我们，在这里你可以收获成功！
    </div>
    <div class="product-wrap">
      <div v-for="item in product" v-bind:key="item.no" class="product" :style="{background: item.bg}">
        <div class="inner">
          <span class="no">{{item.no}}</span>
          <span class="text">{{item.text}}</span>
        </div>
      </div>
    </div>
    <div class="dream-wrap">
      <div v-for="(item,index) in dream" v-bind:key="index" class="dream">
        <span class="text">{{item.title}}</span>
        <span class="text2">{{item.content}}</span>
      </div>
    </div>
    <div class="bottom">
      <div class="address-wrap"> 
        <div class="left">
          <div class="h1">我们在这里</div>
          <div class="text">北京市</div>
          <div class="text2">北京市海淀区尚东数字谷A区3号楼3楼</div>
        </div>
        <img class="address" src="../../assets/address.png"/> 
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderNav from '../../components/HeaderNav'
import Footer from '../../components/Footer'
import bg from '../../assets/header-bg4.png'
import pbg1 from '../../assets/product-bg1.png'
import pbg2 from '../../assets/product-bg2.png'
import pbg3 from '../../assets/product-bg3.png'

export default {
  components: { HeaderNav, Footer },
  data() {
    return {
      bg,
      text: '关于我们',
      product: [{
        no: '01',
        text: '微信公众号',
        bg: `url(${pbg1})`
      }, {
        no: '02',
        text: '小程序',
        bg: `url(${pbg2})`
      }, {
        no: '03',
        text: 'APP',
        bg: `url(${pbg3})`
      }],
      dream: [{
        title: '使命',
        content: 'Inspire Lives  分享和发现世界的精彩'
      }, {
        title: '愿景',
        content: '成为考研人最信任的教育公司'
      }, {
        title: '价值观',
        content: 'Inspire Lives  分享和发现世界的精彩'
      }]
    }
  }
}
</script>

<style lang="less" scoped>
  .h1{
    margin-top: 72px;
    text-align: center;
    font-size: 30px;
    color: #000000;
  }
  .desc{
    width: 734px;
    margin: 0 auto;
    margin-top: 40px;
    font-size: 20px;
    color: #595959;
    line-height: 22px;
  }
  .product-wrap{
    display: flex;
    justify-content: center;
    margin-top: 26px;
    .product{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 184px;
      height: 268px;
      .inner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:114px;
        height:80px;
        background:rgba(255,255,255,0.76);
        .no{
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
  .dream-wrap{
    display: flex;
    justify-content: center;
    margin-top: 48px;
    .dream{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 93px;
      &:last-child{
        margin-right: 0;
      }
      .text{
        margin-top: 0;
        margin-bottom: 12px;
      }
      .text2{
        margin-bottom: 60px;
      }
    }
  }
  .text{
    font-size: 22px;
    color: #333333;
    margin-top: 16px;
  }
  .text2{
    color: #717171;
    font-size: 16px;
  }
  .bottom{
    background: #FBFBFB;
    .address-wrap{
      width: 843px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .h1{
        text-align: left;
      }
      .text{
        margin-top: 20px;
      }
      .text2 {
        margin-top: 10px;
      }
    }
  }
  @media screen and (max-width:500px){
    .desc{
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
    }
    .dream-wrap{
      .dream{
        margin-right: 0;
        padding: 0 12px;
        .text2{
          text-align: center;
        }
      }
    }
    .bottom{
      .address-wrap{
        width: 100%;
        padding: 0 12px;
        box-sizing: border-box;
        flex-wrap: wrap;
        .address{
          width: 100%;
          margin-bottom: 24px;
        }
        .text, .text2{
          margin-top: 6px;
        }
        .text2{
          margin-bottom: 12px;
        }
      }
    }
  }
</style>